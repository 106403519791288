<template>
  <div>
    <span class="primary--text" v-if="!retrying">
      Only upload the two Scan Gauge Scans of a single arch.
    </span>
    <span class="primary--text" v-if="retrying">
      Attempt {{ retryCount + 1 }} - Upload at least one new scan.
    </span>

    <div v-if="retrying && faceCount" class="py-2">
      Expected Gauge Selection Count: <strong> {{ faceCount }} </strong>
      <v-btn small @click="restartSession" class="ml-3 elevation-0 rounded-10">
        Restart
      </v-btn>
    </div>

    <v-data-table
      v-if="retrying"
      :headers="[
        { text: 'Attempt', value: 'attempt' },
        { text: 'Scan 1', value: 'scan1' },
        { text: 'Scan 2', value: 'scan2' }
      ]"
      :items="uploadedScans"
      disable-sort
      hide-default-footer
    />
    <v-card flat>
      <v-card-text class="px-0 pt-3">
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <ScanUploader categoryTitle="Left to Right" v-model="scan1File" />
              <v-file-input
                v-model="scan1File"
                :rules="fileRules"
                label="Left-To-Right Scan"
                class="rounded-10 mt-2"
                dense
                outlined
                truncate-length="25"
                :disabled="!!bypass"
              />
            </v-col>
            <v-col cols="6">
              <ScanUploader categoryTitle="Right to Left" v-model="scan2File" />
              <v-file-input
                v-model="scan2File"
                :rules="fileRules"
                label="Right-To-Left Scan"
                class="rounded-10 mt-2"
                dense
                outlined
                truncate-length="25"
                :disabled="!!bypass"
              />
            </v-col>
          </v-row>
          <div class="d-flex">
            <v-btn
              class="btn-primary"
              @click="handleContinue"
              :disabled="
                loading ||
                (!scansChanged && uploadedScans.length === retryCount)
              "
            >
              <span> Continue </span>
            </v-btn>
            <v-btn
              v-if="
                uploadedScans.length &&
                retrying &&
                !scansChanged &&
                uploadedScans.length === retryCount
              "
              class="elevation-0 rounded-10 ml-3"
              @click="reupload"
            >
              Re-upload Scans
            </v-btn>
            <div class="flex-grow-1 ml-5 mr-1" v-if="loading">
              <span>Uploading...</span>
              <v-progress-linear indeterminate />
            </div>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="uploading"
      max-width="600"
      persistent
      content-class="rounded-10"
    >
      <v-card class="card-bg rounded-10" v-if="uploading">
        <v-card-text class="text-center pt-5">
          <v-row align="center" justify="center">
            <v-col cols="12" class="pa-5">
              <v-progress-circular
                :rotate="-90"
                :width="8"
                :size="200"
                :value="Math.round(50 * currentUpload + uploadProgress / 2)"
                color="primary"
              >
                <span>
                  Uploading File {{ currentUpload + 1 }} of 2
                  <br />
                  {{ Math.round(50 * currentUpload + uploadProgress / 2) }}%
                </span>
              </v-progress-circular>
            </v-col>
          </v-row>
          <p :class="['mt-5']">
            Please wait for your uploads to complete before continuing.
          </p>
          <v-progress-linear
            stream
            buffer-value="0"
            :value="Math.round(50 * currentUpload + uploadProgress / 2)"
            color="success"
            class="rounded-lg pb-3"
            v-if="uploading"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { getTextColour } from "@/utils"
import * as NCFrontend from "@osteon-nexus-connect-lib/nc-frontend"
import apiClient from "@/lib/ApiClient"

import ScanUploader from "./UploadScans/ScanUploader.vue"

export default {
  name: "UploadScans",

  components: { ScanUploader },

  data() {
    return {
      getTextColour,
      loading: false,
      scan1File: null,
      scan2File: null,
      uploadedScan1: null,
      uploadedScan2: null,
      uploadProgress: 0,
      currentUpload: 0,
      uploading: false,
      fileRules: [
        (file) => {
          if (file) {
            const { name } = file
            const ext = name.split(".").pop().toUpperCase()
            if (ext === "STL" || ext === "PLY") {
              return true
            }
            return "File must be an STL or PLY."
          }
          return "You must select a file."
        }
      ]
    }
  },

  computed: {
    ...mapGetters([
      "accessToken",
      "orderDetails",
      "uploadedScans",
      "addScansResponse",
      "faceCount",
      "retryCount",
      "retrying"
    ]),

    scansChanged() {
      if (!this.retrying) return true
      if (this.scan1File === null || this.scan2File === null) return true
      return !(
        this.uploadedScan1 === this.scan1File &&
        this.uploadedScan2 === this.scan2File
      )
    },

    bypass() {
      return (
        (!this.retrying && this.uploadedScans.length) ||
        this.uploadedScans.length === this.retryCount + 1
      )
    }
  },

  methods: {
    ...mapActions([
      "setOrderDetails",
      "setUploadedScans",
      "setAddScansResponse",
      "setTransition",
      "setErrorMessage",
      "restartSession"
    ]),

    async handleContinue() {
      if (!this.$refs.form.validate()) return
      if (this.bypass) return this.$emit("next")

      try {
        this.loading = true
        this.uploadScans()
        if (!this.orderDetails.nc_session_id) {
          const response = await NCFrontend.ApiClient.sessionCreate(
            `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
            `Bearer ${this.accessToken}`
          )
          this.setOrderDetails({
            ...this.orderDetails,
            nc_session_id: response.sessionId
          })
        }
        this.setAddScansResponse(
          await NCFrontend.ApiClient.sessionAddScans(
            `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
            `Bearer ${this.accessToken}`,
            {
              sessionId: this.orderDetails.nc_session_id,
              scan1File: this.scan1File,
              scan2File: this.scan2File,
              retrying: this.retrying
            }
          )
        )
        this.setUploadedScans([
          ...this.uploadedScans,
          {
            attempt: this.uploadedScans.length + 1,
            scan1: this.scan1File.name,
            scan2: this.scan2File.name
          }
        ])
        this.setTransition(true)
        this.$emit("next")
      } catch (err) {
        if (err instanceof NCFrontend.ApiClient.ErrorResponse) {
          switch (err.type) {
            case NCFrontend.ApiClient.ErrorType.IdenticalScanFiles:
              this.setErrorMessage("Identical scans detected.")
              break
            case NCFrontend.ApiClient.ErrorType.ResourceNotFound:
              this.setErrorMessage("Failed to find session.")
              break
            case NCFrontend.ApiClient.ErrorType.MaxValidationRetriesReached:
              this.setErrorMessage("Max retry attempts reached.")
              break
            case NCFrontend.ApiClient.ErrorType.ScansAlreadyUsedInValidation:
              this.uploadedScan1 = this.scan1File
              this.uploadedScan2 = this.scan2File
              this.setErrorMessage(
                "Scans have already been used in an attempt."
              )
              break
            case NCFrontend.ApiClient.ErrorType.ServerError:
              this.setErrorMessage("An internal server error occurred.")
              break
          }
        } else this.setErrorMessage("An internal error occurred.")
        console.error(err)
      } finally {
        this.loading = false
      }
    },

    reupload() {
      this.scan1File = null
      this.scan2File = null
    },

    async uploadScans() {
      this.uploading = true
      this.uploadProgress = 0

      try {
        if (this.uploadedScan1 !== this.scan1File) {
          const form1 = new FormData()
          form1.append("files", this.scan1File)
          form1.append("file_type", "scan")
          // form1.append("file_category", "LR")
          // await client.instance.orders.uploadFile(this, form1)
          this.uploadedScan1 = this.scan1File
        }

        this.currentUpload = 1

        this.uploadProgress = 0
        if (this.uploadedScan2 !== this.scan2File) {
          const form2 = new FormData()
          form2.append("files", this.scan2File)
          form2.append("file_type", "scan")
          // form2.append("file_category", "RL")
          // await client.instance.orders.uploadFile(this, form2)
          this.uploadedScan2 = this.scan2File
        }
      } catch (err) {
        this.setErrorMessage("Failed to upload scans")
      } finally {
        this.uploading = false
        this.currentUpload = 0
      }
    }
  }
}
</script>
