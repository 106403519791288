<template>
  <v-system-bar app class="text-caption white--text" color="secondary">
    <v-img
      contain
      max-width="60"
      class="ml-5"
      :src="require('@/assets/osteon-logo-text.svg')"
    />
  </v-system-bar>
</template>

<script>
export default {
  data() {
    return {
      clockIntervalID: null,
      timeNow: null
    }
  },

  methods: {
    updateTime() {
      this.timeNow = new Date().toLocaleTimeString()
    }
  },

  mounted() {
    this.updateTime()
    this.clockIntervalID = setInterval(this.updateTime, 1000)
  },

  beforeUnmount() {
    if (this.clockIntervalID) {
      clearInterval(this.clockIntervalID)
    }
  }
}
</script>
