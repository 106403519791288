<template>
  <v-card class="pa-2 rounded-10">
    <v-card-title class="title-font mb-1" style="font-weight: 600">
      UNKNOWN IDENTITY
    </v-card-title>

    <v-card-text class="text-body-1 black--text ml-3">
      <v-row align="center">
        <v-col cols="3">
          <v-icon color="primary" size="128">mdi-help-circle</v-icon>
        </v-col>
        <v-col>
          <v-row>
            <v-col>
              The email address or phone number you've used to log in is
              unknown.
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Please contact <strong> {{ settings.tenant_name }} </strong> to
              request access.
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn class="btn-primary" @click="logOut"> Try Again </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import client from "@/lib/ApiClient"

export default {
  name: "CompleteCard",

  computed: {
    ...mapGetters(["settings", "tenantSlug"])
  },

  methods: {
    logOut() {
      this.$cookies.remove("osteonAccessToken")
      window.location = `https://id.osteonmedical.com/logout/?redirect_uri=http://localhost:8081/${client.gateway.tenants.tenantSlug}`
    }
  }
}
</script>
