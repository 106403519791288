<template>
  <div>
    <span class="primary--text">
      To get started, please enter your Nexus Scan Gauge Kit Serial Number.
    </span>
    <v-form ref="form" @submit.prevent="handleContinue">
      <v-row class="pa-0 ma-0 align-center my-5">
        <v-text-field
          v-model="kitSerialNumber"
          :autofocus="true"
          outlined
          dense
          hide-details
          label="Serial Number"
          :rules="rules"
          @change="invalidKitNumber = false"
        />

        <v-btn class="btn-primary ml-3" width="120px" @click="handleContinue">
          <span v-if="!loading"> Continue </span>
          <v-progress-circular v-if="loading" size="28" indeterminate />
        </v-btn>
      </v-row>
    </v-form>
    <v-alert dark color="error" v-if="invalidKitNumber">
      <v-icon class="mr-2"> mdi-alert </v-icon>
      The Serial Number you've entered is not valid.
    </v-alert>
    <div>
      The Kit Serial Number can be found on the top left hand corner of the Kit
      Box, as shown below:
    </div>

    <v-img
      :src="require('@/assets/gauge_kit_serial_no.png')"
      max-width="300px"
      class="my-3"
      contain
    />
    <div class="grey--text text--lighten-1 text-caption">
      Having Trouble? Contact your lab to ensure the kit license is activated.
    </div>
    <v-snackbar :value="alertVisible" color="error" dense prominent>
      <v-icon class="mr-3"> mdi-alert </v-icon>
      {{ alertMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import client from "@/lib/ApiClient"

export default {
  name: "ChooseKit",

  data() {
    return {
      loading: false,
      kitSerialNumber: null,
      kit: null,
      rules: [(v) => !!v, (v) => /^[0-9]+$/.test(v)],
      invalidKitNumber: false,
      alertVisible: false,
      alertMessage: null
    }
  },

  computed: {
    ...mapGetters(["orderDetails"])
  },

  methods: {
    async handleContinue() {
      if (!this.$refs.form.validate() || this.loading)
        return this.showAlert("Must enter a serial Number")
      try {
        this.loading = true
        const { kit_box_id } =
          await client.instance.tenants.authenticateKitSerialNumber(
            this.kitSerialNumber
          )
        this.orderDetails.kit_box_id = kit_box_id
        this.$emit("next")
      } catch {
        this.invalidKitNumber = true
      }
      this.loading = false
    },

    showAlert(message) {
      this.alertVisible = true
      this.alertMessage = message
    },

    clearAlert() {
      this.alertVisible = false
      this.alertMessage = ""
    }
  }
}
</script>
