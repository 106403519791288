<template>
  <div>
    <span class="primary--text">
      Input a patient identifier for your records. <br />
      A case number will be generated later
    </span>
    <v-row class="pa-0 ma-0 mt-2 align-center">
      <v-form ref="form" @submit.prevent="handleContinue">
        <v-text-field
          v-model="orderDetails.patient_reference"
          outlined
          dense
          hide-details
          style="width: 250px; flex-grow: 0"
          :rules="[(v) => !!v]"
          placeholder="Patient ID"
        />
      </v-form>

      <v-btn class="btn-primary ml-3" @click="handleContinue"> Continue </v-btn>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "PatientId",
  computed: {
    ...mapGetters(["orderDetails"])
  },
  methods: {
    handleContinue() {
      if (!this.$refs.form.validate()) return
      this.$emit("next")
    }
  }
}
</script>
