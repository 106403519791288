<template>
  <div>
    <span class="primary--text d-block mb-4">
      Upload additional Scans and Images.
    </span>
    <v-card flat>
      <v-card
        :color="dragOver ? 'card' : 'off'"
        outlined
        :light="dropBoxTextColor === 'black'"
        :dark="dropBoxTextColor === 'white'"
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragOver = true"
        @dragenter.prevent="dragOver = true"
        @dragleave.prevent="dragOver = false"
        style="cursor: pointer"
        :class="{ pulsing: dragOver, 'rounded-10': true, 'mb-5': true }"
      >
        <v-card-text @click="addFile()" v-if="uploadQueue.length === 0">
          <v-row>
            <v-col class="text-center">
              <v-icon size="60" class="mt-5"> mdi-cloud-upload </v-icon>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col class="text-center">
              <h3 v-if="!smallScreen" class="title-font">
                Drop your file(s) or folder here, or click to select them.
              </h3>
              <h3 v-if="smallScreen">Click to select your file(s).</h3>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog
        v-model="uploading"
        max-width="600"
        persistent
        content-class="rounded-10"
      >
        <v-card color="off" class="rounded-10" v-if="uploading">
          <!-- <v-toolbar color="card" :class="[textColour, 'title-font']">
            Uploading
          </v-toolbar> -->
          <v-card-text class="text-center pt-5">
            <v-row align="center" justify="center">
              <v-col cols="12" class="pa-5">
                <v-progress-circular
                  :rotate="-90"
                  :width="8"
                  :size="200"
                  :value="totalUploadProgress"
                  color="primary"
                >
                  <span class="primary--text">
                    Uploading File {{ currentUpload }} of {{ totalUploads }}
                    <br />
                    {{ totalUploadProgress }}%
                  </span>
                </v-progress-circular>
              </v-col>
            </v-row>
            <p :class="['mt-5', textColour]">
              Please wait for your uploads to finish before continuing.
            </p>
            <v-progress-linear
              stream
              buffer-value="0"
              :value="totalUploadProgress"
              color="success"
              class="rounded-lg pb-3"
              v-if="uploading"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card class="elevation-0" v-if="existingUploads.length > 0">
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col
              cols="12"
              lg="6"
              xl="4"
              v-for="(upload, index) in existingUploads"
              :key="`uploaded_${index}`"
            >
              <UploadCard :file="upload" @delete="deleteUpload(upload)" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
    <v-btn @click="$emit('next')" class="btn-primary"> Continue </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { getTextColour } from "@/utils"
import client from "@/lib/ApiClient"
import UploadCard from "./UploadFiles/UploadCard.vue"

export default {
  name: "UploadFiles",

  components: {
    UploadCard
  },
  data() {
    return {
      inputDOMElement: null,
      dragOver: false,
      currentUpload: 0,
      totalUploads: 0,
      uploadProgress: 0,
      uploadQueue: []
    }
  },
  // watch: {
  // question(value) {
  //   // Change  to step? So reset on step change?
  //   this.uploadQueue = value.value ? value.value : []
  // }
  // },
  computed: {
    ...mapGetters(["orderUid", "additionalFiles", "settings"]),

    textColour() {
      return `${getTextColour(this.settings.colours.off)}--text`
    },
    dropBoxTextColor() {
      if (this.dragOver) return getTextColour(this.settings.colours.card)
      else return getTextColour(this.settings.colours.off)
    },
    uploading() {
      return this.uploadQueue.length > 0
    },
    totalUploadProgress() {
      if (this.totalUploads === 0) return 0

      const totalProgress =
        (1 / this.totalUploads) * (this.uploadProgress / 100) +
        (this.currentUpload - 1) / this.totalUploads
      return Math.round(totalProgress * 100 * 100) / 100
    },
    existingUploads() {
      return this.additionalFiles || []
    },
    smallScreen() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      )
    }
  },
  methods: {
    ...mapActions(["setAdditionalFiles"]),
    async onDrop(event) {
      await Promise.all(
        [...event.dataTransfer.items].map(async (item) => {
          const wkItem = item.webkitGetAsEntry()
          if (wkItem.isFile) {
            const fileItem = await new Promise((resolve) =>
              wkItem.file(resolve)
            )
            this.uploadQueue.push(fileItem)
          } else if (wkItem.isDirectory) {
            await this.traverseDirectory(wkItem)
          }
        })
      )
      this.dragOver = false
      this.startUpload()
    },
    async traverseDirectory(wkItem) {
      const entries = await new Promise((resolve) =>
        wkItem.createReader().readEntries(resolve)
      )
      await Promise.all(
        entries.map(async (entry) => {
          if (entry.isFile) {
            const fileItem = await new Promise((resolve) => entry.file(resolve))
            this.uploadQueue.push(fileItem)
          } else if (entry.isDirectory) {
            await this.traverseDirectory(entry)
          }
        })
      )
    },
    addFile() {
      if (!this.inputDOMElement) {
        this.inputDOMElement = document.createElement("input")
        this.inputDOMElement.type = "file"
        this.inputDOMElement.multiple = true
        this.inputDOMElement.addEventListener("change", (event) => {
          const array = [...event.target.files]
          array.forEach((entry) => {
            this.uploadQueue.push(entry)
          })
          this.startUpload()
        })
      }
      this.inputDOMElement.click()
    },
    startUpload() {
      this.currentUpload = 0
      this.$emit("setLock", true)
      this.totalUploads = this.uploadQueue.length
      this.uploadFiles()
    },
    uploadFiles() {
      if (this.uploadQueue.length === 0) {
        this.$emit("setLock", false)
        return
      }
      this.currentUpload++
      this.uploadFile(this.uploadQueue[0])
    },
    async uploadFile(file) {
      this.uploadProgress = 0
      const form = new FormData()
      form.append("files", file)
      form.append("file_type", this.fileType(file))
      client.instance.orders
        .uploadFile(this, form)
        .then((response) => {
          let questionPayload = []
          if (this.additionalFiles.length > 0) {
            questionPayload = this.additionalFiles
          }
          questionPayload.push(response.data)
          this.setAdditionalFiles(questionPayload)
          this.uploadQueue.splice(0, 1)
          this.uploadFiles()
        })
        .catch(() => {
          this.uploadQueue.splice(0, 1)
          this.uploadFiles()
        })
    },
    async deleteUpload(file) {
      const uploads = [...this.additionalFiles]
      const index = uploads.indexOf(file)
      if (index < 0) {
        return
      }
      uploads.splice(index, 1)
      this.setAdditionalFiles(uploads)
      client.instance.orders.deleteFile(this.orderUid, file.uid)
    },
    fileType(file) {
      if (!file?.filename) {
        return
      }
      const fileExt = file.filename.toLowerCase().split(".").pop()
      switch (fileExt) {
        case "jpg":
        case "jpeg":
        case "png":
          return "photo"
        case "ply":
        case "stl":
          return "scan"
        default:
          return "other"
      }
    }
  }
}
</script>
